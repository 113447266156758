const actions = {
  FETCH_CAMERAS: "FETCH_CAMERAS",
  SAVE_CAMERA: "SAVE_CAMERA",
  VIEW_CAMERA: "VIEW_CAMERA",
  ERROR_CAMERA: "ERROR_CAMERA",
  LOAD_BUILDING_METADATA_LIST: "LOAD_BUILDING_METADATA_LIST",
  LOAD_SITE_METADATA_LIST: "LOAD_SITE_METADATA_LIST",
  LOAD_ALL_SITES: "LOAD_ALL_SITES",
  LOAD_SERVER_METADATA_LIST: "LOAD_SERVER_METADATA_LIST",
  SITE_DETAILS: "SITE_DETAILS",
  LOAD_ACTIONS: "LOAD_ACTIONS",
  LOAD_COMMAND_LOGS: "LOAD_COMMAND_LOGS"
};

export default actions;
