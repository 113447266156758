import React, { Component } from 'react';
import { connect } from 'react-redux';
import appActions from '../../redux/app/actions';
import _ from 'lodash';
const { toggleCollapsed } = appActions;

class DashBoardTopbar extends Component {

  render() {
    const { toggleCollapsed, customizedTheme } = this.props;
    //const { toggleCollapsed, url, customizedTheme, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };
    return (
      <div className="page dashbord">
        <div className="isoLeft">
          <button
            className={
              collapsed ? 'leftBtn menuCollapsed' : 'leftBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
        </div>
        {/* <div className="isoMiddle">
        </div>
        <div className="isoRight">
            <button className="top-btn right-button"></button>
        </div> */}
      </div> 
    );
  }
}

export default connect(
  state => ({
    ...state.App.toJS(),
    locale: state.LanguageSwitcher.toJS().language.locale,
    customizedTheme: 'themedefault'
  }),
  { toggleCollapsed }
)(DashBoardTopbar);
