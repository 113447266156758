import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
    importCameraLogList: [],
    siteList: []
});

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_IMPORT_CAMERA_LOGS:
            return state
                .set("importCameraLogList", action.dataArray);
        case actions.LOAD_SITE_LIST:
            return state.set("siteList", action.dataArray);
        default:
            return state;
    }
}
