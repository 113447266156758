import getDevSidebar from "../../customApp/sidebar";

const options = [
  {
    menuid: 1,
    key: "",
    label: "sidebar.dashboard",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "camera",
    label: "sidebar.cameras",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "building",
    label: "sidebar.building",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "alerts",
    label: "sidebar.alerts",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "referenceImages",
    label: "sidebar.referenceImages",
    leftIcon: "proxebuttonBg",
    access: ['oloid.ai', 'proxce.com', 'xcdify.com']
  },
  {
    menuid: 1,
    key: "cameraStatus",
    label: "sidebar.cameraStatus",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "bulkUpload",
    label: "sidebar.bulkUpload",
    leftIcon: "proxebuttonBg"
  },
  {
    menuid: 1,
    key: "report",
    label: "sidebar.report",
    leftIcon: "proxebuttonBg",
    access: ['oloid.ai', 'proxce.com', 'xcdify.com']
  },
  ...getDevSidebar
];

/* const options = [
  {
    menuid: 1,
    key: "experience",
    label: "sidebar.experiences",
    leftIcon: "experienceBg"
  },
  {
    menuid: 1,
    key: "templates",
    label: "sidebar.templates",
    leftIcon: "templatesBg"
  },
  {
    menuid: 1,
    key: "tools",
    label: "sidebar.tools",
    leftIcon: "toolsBg"
  },
  {
    menuid: 1,
    key: "connections",
    label: "sidebar.connections",
    leftIcon: "connectionsBg",
    children: [
      {
        key: "trigger",
        label: "sidebar.trigger",
        leftIcon: "triggerBg",
      },
      {
        key: "action",
        label: "sidebar.action",
        leftIcon: "actionBg",
      }
    ]
  },
  {
    menuid: 1,
    key: "configurations",
    label: "sidebar.configurations",
    leftIcon: "configurationsBg",
    children: [
      {
        key: "location",
        label: "sidebar.location",
        leftIcon: "locationBg",
      },
      {
        key: "video",
        label: "sidebar.video",
        leftIcon: "videoBg",
      },
      {
        key: "proxebutton",
        label: "sidebar.proxebutton",
        leftIcon: "proxebuttonBg",
      },
      {
        key: "bluetoothhub",
        label: "sidebar.bluetoothhub",
        leftIcon: "bluetoothhubBg",
      }
    ]
  },
  {
    menuid: 1,
    key: 'activitylog',
    label: 'sidebar.activitylog',
    leftIcon: "activitylogBg",
  },
  {
    menuid: 2,
    key: "experience",
    label: "sidebar.experiences",
    leftIcon: "ion-bookmark"
  },
  {
    menuid: 2,
    key: "configurations",
    label: "sidebar.configurations",
    leftIcon: "ion-code-working",
    children: [
      {
        key: "location",
        label: "sidebar.location",
        leftIcon: "ion-code-working",
      },
      {
        key: "video",
        label: "sidebar.video",
        leftIcon: "ion-code-working",
      },
      {
        key: "proxebutton",
        label: "sidebar.proxebutton",
        leftIcon: "ion-code-working",
      },
      {
        key: "bluetoothhub",
        label: "sidebar.bluetoothhub",
        leftIcon: "ion-code-working",
      }
    ]
  },
  {
    menuid: 2,
    key: 'activitylog',
    label: 'sidebar.activitylog',
    leftIcon: 'ion-shuffle'
  },
  {
    menuid: 3,
    key: "tools",
    label: "sidebar.tools",
    leftIcon: "ion-hammer"
  },
  {
    menuid: 3,
    key: "connections",
    label: "sidebar.connections",
    leftIcon: "ion-link",
    children: [
      {
        key: "trigger",
        label: "sidebar.trigger",
        leftIcon: "ion-link",
      },
      {
        key: "action",
        label: "sidebar.action",
        leftIcon: "ion-play",
      }
    ]
  },
  {
    menuid: 3,
    key: "configurations",
    label: "sidebar.configurations",
    leftIcon: "ion-code-working",
    children: [
      {
        key: "location",
        label: "sidebar.location",
        leftIcon: "ion-code-working",
      },
      {
        key: "video",
        label: "sidebar.video",
        leftIcon: "ion-code-working",
      },
      {
        key: "proxebutton",
        label: "sidebar.proxebutton",
        leftIcon: "ion-code-working",
      },
      {
        key: "bluetoothhub",
        label: "sidebar.bluetoothhub",
        leftIcon: "ion-code-working",
      }
    ]
  },
  {
    menuid: 3,
    key: 'activitylog',
    label: 'sidebar.activitylog',
    leftIcon: 'ion-shuffle'
  },
  ...getDevSidebar
]; */

export default options;
