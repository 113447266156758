import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
  building: {},
  buildingList: [],
  errorBuilding: '',
  siteList: []
});
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING:
      return state.set("building", action.dataArray);
    case actions.FETCH_BUILDING_LIST:
      return state.set("buildingList", action.dataArray);
    case actions.ERROR_BUILDING:
      return state.set("errorBuilding", action.dataArray);
    case actions.LOAD_SITE_LIST:
      return state.set("siteList", action.dataArray);
    default:
      return state;
  }
}
