import Auth from './auth/reducer';
import App from './app/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import DevReducers from '../customApp/redux/reducers';
import fixSideBarReducer from './SideFixedBar/SideFixBarReducer';
import cameraReducer from './camera/reducer';
import alertReducer from './alerts/reducer';
import referenceImagesReducer from './referenceImages/reducer';
import healthReducer from './health/reducer';
import buildingReducer from './building/reducer';
import bulkUploadReducer from './bulkUpload/reducer';
import dashboardReducer from './dashboard/reducer';
import reportReducer from './report/reducer';

export default {
  Auth,
  App,
  fixSideBarReducer,
  LanguageSwitcher,
  cameraReducer,
  alertReducer,
  referenceImagesReducer,
  healthReducer,
  buildingReducer,
  bulkUploadReducer,
  dashboardReducer,
  reportReducer,
  ...DevReducers
};
