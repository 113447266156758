import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
    responseData: {},
    sites: []
});

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_DASHBOARD_DATA:
            return state.set("responseData", action.dataObject);
        case actions.LOAD_SITE_LIST:
            return state.set("sites", action.dataArray);
        default:
            return state;
    }
}
