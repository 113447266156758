import React, { Component } from 'react';
import TopbarUser from '../../containers/Topbar/topbarUser';
import { Link } from 'react-router-dom';

import LogoImage from '../../image/logo.png';
import Icon1 from '../../image/ic_dashboard.png';
import Icon2 from '../../image/ic_workflow.png';
import Icon3 from '../../image/ic_platform.png';
import Icon1_Active from '../../image/ic_dashboard_active.png';
import Icon2_Active from '../../image/ic_workflow_active.png';
import Icon3_Active from '../../image/ic_platform_active.png';

import { connect } from "react-redux";

import {
    setSelectedMenu
} from "../../actions/sideFixedBarAction";


export class SideFixedBar extends Component {

	componentWillMount() {
		this.setState({
			selectedIndex: 0,
			Lists1: [{id: 1, value: Icon1_Active}],
			// , {id: 2, value: Icon2}, {id: 3, value: Icon3}
			// Lists2: [{id: 1, value: Icon1}, {id: 2, value: Icon2_Active}, {id: 3, value: Icon3}],
			// Lists3: [{id: 1, value: Icon1}, {id: 2, value: Icon2}, {id: 3, value: Icon3_Active}],
			Lists: [{id: 1, value: Icon1_Active}]
		})
	}
  
	handleActive = (index) => {
		this.setState({selectedIndex: index});
		if(index === 0){
			this.setState({Lists: this.state.Lists1});
		}
		if(index === 1){
			this.setState({Lists: this.state.Lists2});
		}
		if(index === 2){
			this.setState({Lists: this.state.Lists3});
		}
		this.props.setSelectedMenu(this.state.Lists[index].id)
	}

	render() {
		const { locale } = this.props;
		
		return (
			<div className="leftBar">
				<ul>
					<li>
						<Link to="/dashboard">
							<div className="logo">
								<img alt="logo" src={LogoImage}/>
							</div>
						</Link>
					</li>
					{this.state.Lists.map((value, index) => (
						<li onClick = {(e) => {this.handleActive(index)}} key={`item-${index}`} className={(this.state.selectedIndex === index ? 'active' : '')}>
							<div className="icon">
								<img alt={value.value} src={value.value}/>
							</div>
						</li>
					))}
				</ul>
				<div className="profile-button isoUser">
					<TopbarUser locale={locale} />
				</div>
			</div>
		);
  	}
}


const mapStateToProps = (state, props) => ({
  Selectedmenu: state.fixSideBarReducer.menuId
});

const mapDispatchToProps = (dispatch, props) => ({
    setSelectedMenu: (id) => dispatch(setSelectedMenu(id))
});

const SideFixedBarContainer = connect(mapStateToProps, mapDispatchToProps)(
    SideFixedBar
);

export default SideFixedBarContainer;

