
export default function(state={menuId :1}, action) {
  switch (action.type.slice(2)) {
    case "SET_SELECTED_MENU":
      return {
        ...state,
        menuId: action.id
      };
      case "SET_NOTE":
      return {
        ...state,
        note: action.note
      };
    default:
      return state;
  }
}