import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { AppLocale } from "../../dashApp";
import themes from "../../settings/themes";
import AppHolder from "./commonStyle";
import "./global.css";
import Auth0 from '../../helpers/auth0';

const { Content } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

//var isNetworkReconnected;

export class App extends Component {
  componentDidMount() {
    this.logoutTimerID = setInterval(() => this.isLogoutRequired(), 5000);
    //this.networkOffID = setInterval(() => this.isBrowserRefreshRequired(), 3000);
    this.refreshBrowserID = setInterval(() => this.refreshBrowser(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.logoutTimerID);
    //clearInterval(this.networkOffID);
    clearInterval(this.refreshBrowserID);
  }

  refreshBrowser() {
    let date = new Date();
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
      window.location.reload(true);
    }
  }

  isLogoutRequired() {
    // check for login expiry
    if (localStorage.getItem('logout_time') !== null) {
      if (Auth0.checkIfLoginExceedsThirtyDays()) {
        console.log(new Date().toISOString() + " App.js - 30days loginExpired, logging user out");
        Auth0.logout();
        clearInterval(this.logoutTimerID);
        //clearInterval(this.networkOffID);
        clearInterval(this.refreshBrowserID);
      } else if (Auth0.loginExpired()) {
        //console.log(new Date().toISOString() + " App.js - 2hrs loginExpired, renewing token ");
        Auth0.checkSession();
      }
    } else {
      console.log(new Date().toISOString() + " App.js - logout_time not found, logging user out");
      Auth0.logout();
      clearInterval(this.logoutTimerID);
      //clearInterval(this.networkOffID);
      clearInterval(this.refreshBrowserID);
    }
  }

  /* isBrowserRefreshRequired() {
    if (navigator.onLine) {
      console.log("browser is ONLINE");
      if (isNetworkReconnected) {
        console.log("browser network RE-CONNECTED");
        window.location.reload();
      }
    } else {
      console.log("browser is OFFLINE");
      isNetworkReconnected = true;
    }
  } */

  render() {
    const { url } = this.props.match;
    let { location } = this.props;
    if(location.pathname === "/dashboard/dashboard") {
      location.pathname = "/dashboard";
    }
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: "100vh" }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} location={location} />
                <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                  <Sidebar url={url} />
                  <Layout
                    className="platformContentMainLayout"
                    style={{
                      height: height
                    }}
                  >
                    <Content
                      className="platformContent"
                      style={{
                        padding: "70px 0 0",
                        flexShrink: "0",
                        background: "#f1f3f6",
                        position: "relative"
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.toJS().language.locale,
    selectedTheme: 'themedefault',
    height: state.App.toJS().height
  }),
  { logout, toggleAll }
)(App);
