import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
  dataList: [],
  devices: [],
  vaults: []
});

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_DATA:
      return state.set("dataList", action.dataObject);
    case actions.FETCH_VAULTS:
      return state.set("vaults", action.dataObject);
    case actions.FETCH_DEVICES:
      return state.set("devices", action.dataObject);
    default:
      return state;
  }
}
