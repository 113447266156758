import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
  alerts: [],
  selectedAlert: {},
  pages: 0,
  totalRecords: 0,
  currentPage: 1,
  currentPageSize: 10,
  siteList: []
});

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ALERTS:
      return state
        .set("alerts", action.dataArray)
        .set("pages", action.pages)
        .set("totalRecords", action.totalRecords)
        .set("currentPage", action.currentPage)
        .set("currentPageSize", action.currentPageSize);
    case actions.VIEW_ALERT:
      return state.set("selectedAlert", action.data);
    case actions.LOAD_SITE_LIST:
      return state.set("siteList", action.dataArray);
    default:
      return state;
  }
}
