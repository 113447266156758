/* jshint esversion: 6 */
/* global module: false */
const base64 = require("base-64");

var environment = process.env.REACT_APP_NODE_ENV || 'development';
console.log("Environment running " + environment);

var SCHEME = 'http';
var UI_HOST_IP,
    SERVICE_HOST_IP, SERVICE_HOST_IP_REPORT,
    SERVICE_PORT;
var basicAuth, itcBasicAuth;
var AUTH = {};
var APP;

var helpURL = 'https://proxce.gitbook.io/ee-monitoring/';

//settings menu hidden
// {
//   "visible": false,
//   "Key": "10062",
//   "icon": " fa fa-fw fa-gear",
//   "title": "Settings",
//   "route": "/account/settings",
//   "isChecked": false
// },

/* Production Environment */
SCHEME = 'https';
UI_HOST_IP = "platform.proxce.ai";
SERVICE_HOST_IP = "platform.proxce.ai/api";
SERVICE_HOST_IP_REPORT = "platform.proxce.ai/fb";

AUTH = {
    /* TMS UI PROD Interactive Client Details */
    "CLIENT_ID": "tRpbnDE6HwesF4hC3agBqUTt7jbTB5Aa",
    "CLIENT_DOMAIN": "oloid.auth0.com",
    "basicUserName": "proxceEE",
    "basicPassName": "proxce123",
    "ITCbasicUserName": "oloidProdITC",
    "ITCbasicPassword": "oloidProd@123"
};
APP = {
    "AUTH_REDIRECT_URL": SCHEME + "://" + UI_HOST_IP + "/dashboard",
    "MASKING_PAGE_URL": SCHEME + "://" + UI_HOST_IP + "/masking/index.htm",
    "WORKING_MASKING_PAGE_URL": "http://54.201.221.255:5000/masking/index.htm",
    "LOGOUT_URL": SCHEME + "://" + UI_HOST_IP,
    "LIST_CAMERA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getCameraMetadata",
    "ADD_CAMERA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/newCameraMetadata",
    "UPDATE_CAMERA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/updateCameraMetadata",
    "BUILDING_METADATA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getBuildingMetadata",
    "SITE_METADATA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getSiteMetadata",
    "SERVER_METADATA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getServerMetadata",
    "UPDATE_USER_URL": SCHEME + "://" + SERVICE_HOST_IP + "/updateUser",
    "DEACTIVATE_CAMERA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/deactivateCamera",
    "FETCH_ALERTS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getAlertRecords",
    "FETCH_REF_IMGS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getReferenceImageRecords",
    "DOWNLOAD_MASK_IMAGE_URL": SCHEME + "://" + SERVICE_HOST_IP + "/downloadMaskImage?proxceCameraID=",
    "GET_CAMERA_STATUS_REPORTS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getCameraStatusReportRecords",
    "DOWNLOAD_CAMERA_STATUS_REPORT_URL": SCHEME + "://" + SERVICE_HOST_IP + "/downloadCameraStatusReport?fileID=",
    "ADD_BUILDING_URL": SCHEME + "://" + SERVICE_HOST_IP + "/newBuildingMetadata",
    "UPDATE_BUILDING_URL": SCHEME + "://" + SERVICE_HOST_IP + "/updateBuildingMetadata",
    "IMPORT_CAMERAS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/importCameras",
    "FETCH_IMPORT_CAMERA_LOGS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/fetchImportCameraLogs",
    "FETCH_SAMPLE_IMPORT_CAMERA_FILE_URL": SCHEME + "://" + SERVICE_HOST_IP + "/fetchImportCamerasSampleFile",
    "FETCH_DASHBOARD_DATA_URL": SCHEME + "://" + SERVICE_HOST_IP + "/fetchDashboardData",
    "FETCH_CAMERA_LIST_URL": SCHEME + "://" + SERVICE_HOST_IP + "/getCameraList",
    "DOWNLOAD_IMAGES_URL": SCHEME + "://" + SERVICE_HOST_IP + "/downloadReferenceImageRecords",
    "DOWNLOAD_REF_IMAGE_URL": SCHEME + "://" + SERVICE_HOST_IP + "/downloadReferenceImage?refID=",
    "EXPORT_CAMERAS_URL": SCHEME + "://" + SERVICE_HOST_IP + "/exportCamerasForInvoicingCheck",
    "GET_ACTIONS": SCHEME + "://" + SERVICE_HOST_IP + "/getActions",
    "GET_COMMAND_LOGS": SCHEME + "://" + SERVICE_HOST_IP + "/getCommandLogs",
    "NEW_COMMAND_URL": SCHEME + "://" + SERVICE_HOST_IP + "/addNewCommand",
    "FETCH_DATA_URL": SCHEME + "://" + SERVICE_HOST_IP_REPORT + "/getRecords",
    "FETCH_VAULTS_URL": SCHEME + "://" + SERVICE_HOST_IP_REPORT + "/getVaultIds",
    "FETCH_DEVICES_URL": SCHEME + "://" + SERVICE_HOST_IP_REPORT + "/getDeviceCodes",
    "EXPORT_FILE_URL": SCHEME + "://" + SERVICE_HOST_IP_REPORT + "/exportRecordsToFile",
};

basicAuth = createBasicAuth(AUTH.basicUserName, AUTH.basicPassName);
itcBasicAuth = createBasicAuth(AUTH.ITCbasicUserName, AUTH.ITCbasicPassword);

function createBasicAuth(user, password) {
    var tok = user + ':' + password;
    var hash = base64.encode(tok);
    return "Basic " + hash;
}

APP.DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";
module.exports = {
    "AUTH": AUTH,
    "APP": APP,
    "BASIC_AUTH": basicAuth,
    "ITC_BASIC_AUTH": itcBasicAuth,
    "HELP_URL": helpURL
};
