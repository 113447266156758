import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarWrapper from './topbar.style';
import _ from 'lodash'
import DashBoardTopbar from './dashBoard';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.getTopBar = this.getTopBar.bind(this);
  }
  getTopBar(page) {
    const { toggleCollapsed, customizedTheme } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const pageName = page.pathname.split('/');
    if (page.pathname === "/dashboard") {
      return <DashBoardTopbar />;
    }
    else {
      let siteNames = '';
      if (localStorage.getItem('userRole') !== 'SuperAdmin') {
        siteNames = localStorage.getItem('siteName');
      }
      return (
        <div style={{ width: '100%' }}>
          <button
            className={
              collapsed ? 'leftBtn menuCollapsed' : 'leftBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
          <span className="page_name">{pageName ? _.last(pageName) : ''}</span>
          <span className="site_name">{siteNames}</span>
        </div>
      )
    }
  }
  render() {
    const { toggleCollapsed, customizedTheme, location } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };
    //var pageName = location.pathname.split('/');
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'platformTopbar collapsed' : 'platformTopbar'
          }
        >
          {this.getTopBar(location)}
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App.toJS(),
    locale: state.LanguageSwitcher.toJS().language.locale,
    customizedTheme: 'themedefault'
  }),
  { toggleCollapsed }
)(Topbar);
