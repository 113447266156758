import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import customRoutes from "../../customApp/router";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Dashboard"))
  },
  {
    path: "dashboard",
    component: asyncComponent(() => import("../Dashboard"))
  },
  {
    path: "report",
    component: asyncComponent(() => import("../Report"))
  },
  {
    path: "camera",
    component: asyncComponent(() => import("../Camera"))
  },
  {
    path: "alerts",
    component: asyncComponent(() => import("../Alerts"))
  },
  {
    path: "referenceImages",
    component: asyncComponent(() => import("../ReferenceImages"))
  },
  {
    path: "cameraStatus",
    component: asyncComponent(() => import("../CameraStatus"))
  },
  {
    path: "building",
    component: asyncComponent(() => import("../Building"))
  },
  {
    path: "bulkUpload",
    component: asyncComponent(() => import("../BulkUpload"))
  },
  {
    path: "register",
    component: asyncComponent(() => import("../Page/register"))
  },
  ...customRoutes
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
