import { Map } from "immutable";
import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = new Map({
  cameras: [],
  sites: [],
  allSites: [],
  buildingList: [],
  servers: [],
  siteID: '',
  siteName: '',
  customerID: '',
  customerName: '',
  country: '',
  city: '',
  pages: 0,
  totalRecords: 0,
  currentPage: 1,
  currentPageSize: 10,
  timezone: '',
  actions: [],
  commandLogs: []
});
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CAMERAS:
      return state.set("cameras", action.dataArray)
        .set("pages", action.pages)
        .set("totalRecords", action.totalRecords)
        .set("currentPage", action.currentPage)
        .set("currentPageSize", action.currentPageSize);
    case actions.LOAD_BUILDING_METADATA_LIST:
      return state.set("buildingList", action.dataArray);
    case actions.LOAD_SITE_METADATA_LIST:
      return state.set("sites", action.dataArray);
    case actions.LOAD_ALL_SITES:
      return state.set("allSites", action.dataArray);
    case actions.LOAD_SERVER_METADATA_LIST:
      return state.set("servers", action.dataArray);
    case actions.SITE_DETAILS:
      if (action.siteName !== '')
        localStorage.setItem("siteName", action.siteName);
      return state
        .set("siteID", action.siteID)
        .set("siteName", action.siteName)
        .set("customerID", action.customerID)
        .set("customerName", action.customerName)
        .set("country", action.country)
        .set("city", action.city)
        .set("timezone", action.timezone);
    case actions.LOAD_ACTIONS:
      return state.set("actions", action.dataArray);
    case actions.LOAD_COMMAND_LOGS:
      return state.set("commandLogs", action.dataArray);
    default:
      return state;
  }
}
