import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';

import experienceIcon from '../../image/flowDesigner/experiences_icon.png';
import selectedExperienceIcon from '../../image/flowDesigner/experiences_select_icon.png';
import templatesIcon from '../../image/flowDesigner/templates_icon.png';
import selectedTemplatesIcon from '../../image/flowDesigner/templates_select_icon.png';
import toolsIcon from '../../image/flowDesigner/tools_icon.png';
import selectedToolsIcon from '../../image/flowDesigner/tools_select_icon.png';
import connectionsIcon from '../../image/flowDesigner/connections_icon.png';
import selectedConnectionsIcon from '../../image/flowDesigner/connections_select_icon.png';
import triggerIcon from '../../image/flowDesigner/submenu_trigger_icon.png';
import selectedTriggerIcon from '../../image/flowDesigner/submenu_trigger_select_icon.png';
import actionIcon from '../../image/flowDesigner/submenu_action_icon.png';
import selectedActionIcon from '../../image/flowDesigner/submenu_action_select_icon.png';
import configurationsIcon from '../../image/flowDesigner/configurations_icon.png';
import selectedConfigurationsIcon from '../../image/flowDesigner/configurations_select_icon.png';
import locationIcon from '../../image/flowDesigner/submenu_location_icon.png';
import selectedLocationIcon from '../../image/flowDesigner/submenu_location_select_icon.png';
import videoIcon from '../../image/flowDesigner/submenu_video_icon.png';
import selectedVideoIcon from '../../image/flowDesigner/submenu_video_select_icon.png';
import proxebuttonIcon from '../../image/flowDesigner/submenu_proxce_button_icon.png';
import selectedProxebuttonIcon from '../../image/flowDesigner/submenu_proxce_button_select_icon.png';
import activitylogIcon from '../../image/flowDesigner/activity_log_icon.png';
import selectedActivitylogIcon from '../../image/flowDesigner/activity_log_select_icon.png';

const SidebarWrapper = styled.div`
  .leftBar {
    position: absolute;
    width: 75px;
    left: 0;
    height: 100%;
    top: 0px;
    background: #f2f6fa;
    z-index: 1000;
    border-right: 1px solid #e0e0e0;

    .profile-button{
      position: absolute;
      bottom: 10px;
      text-align: center;
      left: 0px;

      .isoImgWrapper{
        width: 72px;
        height: 72px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        background-color: #f2f6fa;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-transition: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        cursor: pointer;

        img{
          height: 100%;
          object-fit: cover;
        }
        .userActivity{
          width: 10px;
          height: 10px;
          display: block;
          background-color: #7ED321;
          position: absolute;
          bottom: 15px;
          right: 15px;
          border: 1px solid #ffffff;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-transition: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    ul{
      text-align: center;

      li:first-child{
        height: 70px;
        padding: 0px;
        border-bottom: 1px solid #e0e0e0;

        img{
          width: 70px;
        }
      }
      li{
        padding-top: 20px;
        height: 55px;
        cursor: pointer;
        
        .icon{
          border-right: 2px solid transparent;
          img{
            width: 30px;
          }
        } 
      }

      li.active{
        .icon{
          border-right: 2px solid #0185e9;
        }
      }
    }
  }
  
  .platformSidebar.ant-layout-sider {
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
  }

  .platformSidebar.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }

  .platformSidebar {
    z-index: 1000;
    background: ${palette('secondary', 0)};
    width: 240px;
    flex: 0 0 240px;
    left: 75px;
    margin-right: 75px;
  
    .ant-layout-sider-children{
      box-shadow: inset -8px 0px 15px -6px #e8e8e8;
    }
    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 60px;
      background: #f2f6fa;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      box-shadow: inset -8px 0px 15px -6px #e8e8e8;
      ${borderRadius()};

      h3 {
        a {
          font-size: 30px;
          line-height: 70px;
          letter-spacing: 0px;
          color: ${palette('grayscale', 6)};
          display: block;
          text-decoration: none;
          font-weight: 700;
          text-align: left;
          margin-left: 12px;
        }
      }
    }

    &.ant-layout-sider-collapsed {

      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 600;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 520;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }

      .ant-collapse-header >div >span{
        font-weight: bold;
      }

      .ant-collapse-content > div > li{
        font-weight: bold;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        .menu-counter{
          position: absolute;
          right: 20px;
          color: #0185e9;
        }
        
        i {
          font-size: 19px;
          color: #303b4e;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 18px' : '0 18px 0 0'};
          width: 20px;
          height: 20px;
          background-size: 20px;
          ${transition()};
        }
        i.experienceBg{
          background-image: url(${experienceIcon});
        }
        i.templatesBg{
          background-image: url(${templatesIcon});
        }
        i.toolsBg{
          background-image: url(${toolsIcon});
        }
        i.connectionsBg{
          background-image: url(${connectionsIcon});
        }
        i.triggerBg{
          background-image: url(${triggerIcon});
        }
        i.actionBg{
          background-image: url(${actionIcon});
        }
        i.configurationsBg{
          background-image: url(${configurationsIcon});
        }
        i.locationBg{
          background-image: url(${locationIcon});
        }
        i.videoBg{
          background-image: url(${videoIcon});
        }
        i.proxebuttonBg{
          background-image: url(${proxebuttonIcon});
        }
        i.activitylogBg{
          background-image: url(${activitylogIcon});
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        color: #303b4e;
        font-weight: 520;
        ${transition()};
      }

      li.ant-menu-item-active {
        &:hover {
          i,
          .nav-text {
            color: #289cf5;
          }
        }
      }

      .ant-menu-item-selected {
        background-color: #f2f6fa !important;
        box-shadow: inset -8px 0px 15px -6px #e8e8e8;

        .anticon {
          color: #289cf5;
        }

        i {
          color: #289cf5;
        }

        i.experienceBg{
          background-image: url(${selectedExperienceIcon});
        }
        i.templatesBg{
          background-image: url(${selectedTemplatesIcon});
        }
        i.toolsBg{
          background-image: url(${selectedToolsIcon});
        }
        i.connectionsBg{
          background-image: url(${selectedConnectionsIcon});
        }
        i.triggerBg{
          background-image: url(${selectedTriggerIcon});
        }
        i.actionBg{
          background-image: url(${selectedActionIcon});
        }
        i.configurationsBg{
          background-image: url(${selectedConfigurationsIcon});
        }
        i.locationBg{
          background-image: url(${selectedLocationIcon});
        }
        i.videoBg{
          background-image: url(${selectedVideoIcon});
        }
        i.proxebuttonBg{
          background-image: url(${selectedProxebuttonIcon});
        }
        i.activitylogBg{
          background-image: url(${selectedActivitylogIcon});
        }

        .nav-text {
          color: #289cf5;
        }
      }

      > li.ant-menu-item-active {
        &:hover {
          i,
          .nav-text {
            color: #289cf5;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }

    .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a{
      color: ${palette('secondary', 2)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${'' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */};
        }

        &:hover {
          .ant-menu-submenu-arrow {
            display: none

            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '74px !important'};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 520;
          margin: 0;
          color: inherit;
          background-color: #f2f6fa !important;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;

        .ant-menu-item{
          box-shadow: inset -8px 0px 15px -6px #e8e8e8
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline >  {

        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
