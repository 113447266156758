import { Auth0LockPasswordless } from 'auth0-lock';
import history from './history';
import {
	notification
} from '../../components';

const setting = require('../../config/settings');
var CryptoJS = require("crypto-js");
let appCustomers;
let currentUser = null;
// 30 days time in milliseconds
const TOKEN_EXPIRE_TIME = 2592000000;
//var refreshBrowserOnNetworkConnection = true;

const customerJSON = [{ "_id": "5ba9a70f61861526509b89a6", "updateDate": "2018-09-25T03:17:59.000Z", "insertDate": "2018-09-25T03:10:07.000Z", "status": true, "userId": "595f0199cc49715253d5c8d3", "TMS_MENU_APP_KEYS": "", "TEAM_MANAGER_EMAIL": "flexdemo@mailinator.com,madhu@proxce.com", "CONTACT_EMAIL": "14084808785", "NAME": "Flex", "ID": "10000010046", "__v": 0 }];

const authOptions = {
	passwordlessMethod: "link",
	auth: {
		redirectUrl: setting.APP.AUTH_REDIRECT_URL,
		responseType: 'token'
	},
	allowedConnections: ["email"],
	closable: false,
	scope: "openid email profile user_metadata app_metadata metadata",
	autoParseHash: false
};

class Auth0Helper {

	lock = new Auth0LockPasswordless(
		setting.AUTH.CLIENT_ID,
		setting.AUTH.CLIENT_DOMAIN,
		authOptions);

	constructor() {
		this.handleAuthentication();
		this.setCustomer();
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.loginExpired = this.loginExpired.bind(this);
	}

	login(handleLogin) {
		this.lock.show();
	}

	setCustomer() {
		/* const url = setting.APP.CUSTOMERS + "?token=" + setting.AUTH.CLIENT_ID;
		fetch(url)
			.then((resp) => resp.json())
			.then(function (json) {
				if (json && json.message && json.message.hasOwnProperty('CUSTOMERS')) { */
		// customer hardcoded
		var custStr = JSON.stringify(customerJSON/* json.message.CUSTOMERS */);
		var encCustomer = CryptoJS
			.AES
			.encrypt(custStr, setting.AUTH.CLIENT_ID);
		localStorage.setItem('demCust', encCustomer.toString());
		/* appCustomers = customerJSON;
			} else {
				localStorage.setItem('demoCust', null);
			}
		})
		.catch(function (error) {
			console.log(error);
		}); */
	}

	setToken(accessToken) {
		localStorage.setItem('access_token', accessToken);
	}

	getToken() {
		// Retrieves the user token from local storage
		return localStorage.getItem('access_token');
	}
	clearStorage() {
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('registered');
		localStorage.removeItem('profile');
		localStorage.removeItem('user');
		localStorage.removeItem('customer');
		localStorage.removeItem('expires_in');
		localStorage.removeItem('email');
		localStorage.removeItem('demCust');
		localStorage.removeItem('siteID');
		localStorage.removeItem('siteName');
		localStorage.removeItem('domain');
		localStorage.removeItem('registered');
		localStorage.removeItem('customerID');
		localStorage.removeItem('userRole');
		localStorage.removeItem('navItems');
		localStorage.removeItem('logout_time');
		localStorage.removeItem('link');
		localStorage.clear();
	}

	createUser(profile) {
		var user = {};
		if (profile) {
			user.name = profile.name;
			if (profile.hasOwnProperty('user_metadata') && profile.user_metadata.hasOwnProperty('name')) {
				user.name = profile.user_metadata.name;
			}
			if (profile.hasOwnProperty('user_metadata') && profile.user_metadata.hasOwnProperty('lastname')) {
				user.name = user.name + " " + profile.user_metadata.lastname;
			}
			if (profile.hasOwnProperty('user_metadata') && profile.user_metadata.hasOwnProperty('customer_id')) {
				user.customer_id = profile.user_metadata.customer_id;
			}
			if (profile.hasOwnProperty('user_metadata') && profile.user_metadata.hasOwnProperty('team_manager')) {
				user.team_manager = profile.user_metadata.team_manager;
			}
			if (profile.hasOwnProperty('user_metadata') && profile.user_metadata.hasOwnProperty('phone')) {
				user.phone = profile.user_metadata.phone;
			}
			user.user_id = profile.identities && profile
				.identities[0]
				.hasOwnProperty('user_id') && profile.identities[0].user_id ?
				profile.identities[0].user_id :
				profile.user_id;
			user.nickname = profile.nickname;
			user.email = profile.email;
			user.picture = profile.picture;
			user.role = profile.roles && profile.roles[0];
			user.email_verified = profile.email_verified;
		}
		return user;
	}
	getTheUser() {
		var that = this;
		return new Promise((fulfill, reject) => {
			var user = {};
			var token = localStorage.getItem('access_token');
			if (token) {
				var tempUser = localStorage.getItem('user');
				if (tempUser) {
					fulfill(JSON.parse(tempUser));
				} else {
					that
						.lock
						.getUserInfo(token, function (error, profile) {
							if (error) {
								that.logout();
								return;
							}
							user = that.createUser(profile);
							currentUser = user;
							localStorage.setItem('user', JSON.stringify(user));

							fulfill(user);

						});
				}
			}
		});
	}

	handleProfileInfo(accessToken, expiresIn) {
		var that = this;
		if (accessToken) {
			console.log("Fetching new accessToken");
			that
				.lock
				.getUserInfo(accessToken, function (error, profile) {
					// console.log("in handleProfileInfo" + JSON.stringify(profile));
					if (error) {
						console.log('Error in getUserInfo()');
						that.logout();
						return;
					}
					// set the userID
					if (profile.hasOwnProperty('sub')) {
						localStorage.setItem("id_token", profile.sub);
						localStorage.setItem("domain", profile.name.split("@")[1]);
					}

					let keys = Object.keys(profile), appMetadataName, userMetadataName;
					// added for testing
					//localStorage.setItem('keyData-test', keys);
					for (let i = 0; i < keys.length; i++) {
						if (keys[i].includes('app_metadata'))
							appMetadataName = keys[i];
						if (keys[i].includes('user_metadata'))
							userMetadataName = keys[i];
					}

					// check for email in profile
					if (profile.hasOwnProperty('name')) {
						localStorage.setItem('email', profile.name);
					}

					// check for siteID
					if (profile.hasOwnProperty(appMetadataName) && profile[appMetadataName].hasOwnProperty('siteID')) {
						console.log("siteID exists");
						localStorage.setItem('registered', true);
						localStorage.setItem('siteID', profile[appMetadataName].siteID);
						localStorage.setItem('userRole', profile[appMetadataName].roles[0]);
						localStorage.setItem('customerID', profile[userMetadataName].customerID);
						var user = that.createUser(profile);
						currentUser = user;
						localStorage.setItem('user', JSON.stringify(user));
					} else {
						localStorage.setItem('registered', false);
						console.log("siteID does not exist");
						history.replace('/register');
					}
				});

			localStorage.setItem('expires_in', expiresIn);

			var logoutTime = new Date();
			logoutTime.setSeconds(expiresIn);
			localStorage.setItem('logout_time', logoutTime.getTime());

			this.setToken(accessToken);

		} else {
			notification('error', 'Wrong mail or password');
		}
	}

	handleAuthentication(props) {
		var that = this;

		this.lock.on('authenticated', authResult => {
			console.log("authenticated event fired " + JSON.stringify(authResult));
			if (authResult && authResult.accessToken) {

				that.handleProfileInfo(authResult.accessToken, authResult.expiresIn);

			} else {
				notification('error', 'Wrong mail or password');
			}
		});

		// Add a callback for Lock's `authorization_error` event
		this.lock.on('authorization_error', (err) => {
			console.log("authorization_error " + JSON.stringify(err));
			//alert(`Error: ${err.error}. Check the console for further details.`);
			alert("Your session has expired. Please try again.");
			history.replace('/');
		});
	}

	logout() {
		var that = this;
		this.clearStorage();
		history.replace('/');
		that.lock.logout();
	}

	checkSession() {
		var that = this;
		if (navigator.onLine) {
			//console.log("The browser is ONLINE ");

			this.lock.checkSession({}, function (error, authResult) {
				if (error || !authResult) {
					//console.log("Network issue " + JSON.stringify(error));
				} else {
					that.setLoginTime();
					// user has an active session, so we can use the accessToken directly.
					localStorage.setItem('expires_in', authResult.expiresIn);

					var logoutTime = new Date();
					logoutTime.setSeconds(authResult.expiresIn);
					localStorage.setItem('logout_time', logoutTime.getTime());

					that.setToken(authResult.accessToken);
				}
			});
		} /* else {
			console.log("The browser is OFFLINE");
		} */
	}

	setLoginTime() {
		if (!localStorage.getItem('lastLoginTime')) {
			let currentTime = new Date().getTime();
			localStorage.setItem('lastLoginTime', currentTime);
			console.log("setLoginTime - loginTime setNew " + currentTime);
		}
	}

	getLastLogin() {
		if (localStorage.getItem('lastLoginTime') && !isNaN(localStorage.getItem('lastLoginTime'))) {
			//console.log("getLastLogin - " + localStorage.getItem('lastLoginTime'));
			return localStorage.getItem('lastLoginTime');
		} else {
			let currentTime = new Date().getTime();
			localStorage.setItem('lastLoginTime', currentTime);
			console.log("getLastLogin - setNew - " + currentTime);
			return currentTime;
		}
	}

	loginExpired() {
		//console.log(new Date().toISOString() + " App.js - checking loginExpired: seconds left - " + (localStorage.getItem('logout_time') - new Date().getTime()) / 1000);
		return new Date().getTime() > parseInt(localStorage.getItem('logout_time'));
	}

	checkIfLoginExceedsThirtyDays() {
		return ((new Date().getTime()) - this.getLastLogin()) >= TOKEN_EXPIRE_TIME;
	}

	isLogoutRequired() {
		if (localStorage.getItem('logout_time') !== null) {
			if (this.loginExpired()) {
				//console.log("auth0 - 2hrs loginExpired, renewing token");
				this.checkSession();
			} else if (this.checkIfLoginExceedsThirtyDays()) {
				//console.log("auth0 - 30days loginExpired, logging user out");
				this.logout();
			}
		} else {
			//console.log("auth0 - logout_time not found, logging user out");
			this.logout();
		}
	}
}

export default new Auth0Helper();