import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/auth0/history';

import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/logo-profile.png';
import authAction from '../../redux/auth/actions';
import Auth0Helper from '../../helpers/auth0/index';
import TopbarDropdownWrapper from './topbarDropdown.style';

const setting = require('../../config/settings');
const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  clearStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('user');
    localStorage.removeItem('customer');
    localStorage.removeItem('service_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('ui_expires_in');
    localStorage.removeItem('navItems');
    localStorage.removeItem('logout_time');
  }
  logout(e) {
    //debugger;
    Auth0Helper.logout();
    /* e.preventDefault();    
    this.clearStorage();
    history.replace('/'); */
    //this.props.logout();   
    //this.showLock();
  }
  handleHelp = () => {
    window.open(setting.HELP_URL);
  };

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {/* <a className="isoDropdownLink">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink">
          <IntlMessages id="sidebar.feedback" />
        </a> */}
        <a className="isoDropdownLink" onClick={this.handleHelp}>
          <IntlMessages id="topbar.help" />
        </a>
        <a className="isoDropdownLink" onClick={ this
          .logout
          .bind(this) }>
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(null, { logout })(TopbarUser);
